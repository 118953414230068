import React from "react";

const ExperienceItem = ({ title, link, roles = [] }) => {
  return (
    <div className="experienceItem">
      <h3 className="experienceItemTitle">
        {link ? (
          <a href={link} target="_blank" rel="noreferrer">
            <b>{title}</b>
          </a>
        ) : (
          <b>{title}</b>
        )}
      </h3>
      {roles.map((role, index) => (
        <div key={index} className="experience-item-description">
          {role.years}
          <div className="in-div">|</div>
          {role.position}
        </div>
      ))}
    </div>
  );
};

export default ExperienceItem;
