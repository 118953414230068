import React from "react";
import clsx from "clsx";

const borderColorMap = {
  orange: "border-orange",
  blue: "border-blue",
  purple: "border-purple",
};

const Section = (props) => {
  const { children, borderColor, title, ...restProps } = props;

  const borderColorClass = borderColorMap[borderColor];

  return (
    <>
      <section className={"fullsection bordered-section"} {...restProps}>
        <div
          className={clsx([
            "border-right flex-grow",
            "section-work-experience",
            borderColorClass,
          ])}
        >
          <div className="section-center-wrap">{children}</div>
        </div>
        <h2
          className={clsx(
            [`border-right header-sticky headerSlideInOut`],
            borderColorClass
          )}
        >
          {title}
        </h2>
      </section>
    </>
  );
};

export default Section;
